<template>
  <div class="camp-election-template pt-5">
    <page-header v-if="allowPage" class="d-flex align-items-center">
      <h1 style="text-align: center" class="flex-1 mb-3 mb-sm-0">{{ translations.tcCampElection }}</h1>
      <b-button
        variant="primary"
        v-if="user_accessible"
        class="flex-0 mr-0 d-block w-100-sd"
        @click="handleEditClick()"
      >
        {{ translations.tcEdit }}
      </b-button>
    </page-header>
    <div v-if="!allowPage">
      <page-header class="d-flex align-items-center">
        <h1 class="flex-1 mb-3" style="text-align: center">{{ translations.tcCampElection }}</h1>
      </page-header>
      <security-banner :i18n="translations.components"></security-banner>
    </div>
    <div v-if="allowPage">
      <page-body class>
        <section class="section-1 bg-block mb-4">
          <div class="d-flex">
            <div class="col flex-0 w-100-sd">
              <h4 class="mb-2 mb-sm-4">{{ translations.tcCampNumber }}</h4>
              <p class="mb-4 mb-sm-0">{{ currentCamp.campnumber }}</p>
            </div>
            <div class="col flex-0 w-100-sd">
              <h4 class="mb-2 mb-sm-4">{{ translations.tcCampName }}</h4>
              <p class="mb-4 mb-sm-0">{{ currentCamp.campname }}</p>
            </div>
            <div class="col flex-0 w-100-sd">
              <h4 class="mb-2 mb-sm-4">{{ translations.tcElectionDate }}</h4>
              <p class="d-flex align-items-center mb-4 mb-sm-0">
                <iCalendar />
                <span style="padding-left: 10px">{{ committeeDetails.cmt_election_date }}</span>
              </p>
            </div>
            <div class="col flex-0 w-100-sd">
              <h4 class="mb-2 mb-sm-4">{{ translations.tcConductedBy }}</h4>
              <p class="mb-sm-0">{{ committeeDetails.name }}</p>
            </div>
          </div>
          <hr />
          <p class="section-1-notes"><b>{{ translations.tcInstructionsHint }}</b><br/>{{ translations.tcOnlyChangeInfoForNewlyElectedOrAppointed }}</p>
        </section>
        <section class="section-2 bg-block mb-4">
          <header class="toggle-header mb-3">
            <h3 style="margin-bottom: 34px; color: #000">{{ translations.tcGideonElectedOfficers }}</h3>
          </header>
          <div class="body">
            <div class="members d-flex">
              <div class="member w-1_3" v-for="(member, index) in gideonMemberDetails" :key="index">
                <h3 class="title text-left">{{ member.office }}</h3>
                <div v-if="member.name.toLowerCase() !== 'unfilled'" class="name">{{ member.name }}</div>
                <div v-else class="name">{{ translations.tcUnfilled }}</div>
                <div v-if="member.start_date" class="start-date d-flex align-items-center">
                  <iCalendar />
                  {{ translations.tcStartDate }}: {{ member.start_date }}
                </div>
                <div class="months-served" v-if="member.name && member.months_served">
                  {{ translations.tcMonthsServed }}: {{ member.months_served }}
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="section-3 bg-block mb-4">
          <header class="toggle-header mb-3">
            <h3 style="margin-bottom: 34px; color: #000">{{ translations.tcAuxiliaryElectedOfficers }}</h3>
          </header>
          <div class="body">
            <div class="members d-flex">
              <div class="member w-1_3" v-for="(aux, index) in auxiliaryMemberDetails" :key="index">
                <h3 class="title text-left">{{ aux.office }}</h3>
                <div v-if="aux.name.toLowerCase() !== 'unfilled'" class="name">{{ aux.name }}</div>
                <div v-else class="name">{{ translations.tcUnfilled }}</div>
                <div v-if="aux.start_date" class="start-date d-flex align-items-center">
                  <iCalendar />
                  {{ translations.tcStartDate }}: {{ aux.start_date }}
                </div>
                <div v-if="aux.name && aux.months_served" class="months-served">
                  {{ translations.tcMonthsServed }}: {{ aux.months_served }}
                </div>
              </div>
            </div>
          </div>
        </section>
      </page-body>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapGetters, mapActions } from 'vuex'
import BackJump from '@/components/back-jump/BackJump.vue'
import constantData from '@/json/data.json'
import iCalendar from '@/assets/svgs/iCalendar.vue'
import pageBody from '@/components/page-components/PageBody.vue'
import pageFooter from '@/components/page-components/PageFooter.vue'
import pageHeader from '@/components/page-components/PageHeader.vue'
import SecurityBanner from '@/components/SecurityBanner'
import { securityMixin } from '@/mixins/securityMixin'
import ToggleArrow from '@/components/toggle-arrow/ToggleArrow.vue'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'view-camp-election',
  mixins: [translationMixin, securityMixin],
  data() {
    return {
      translations: {},
      secured_controls: {
        edit_camp_elections: 'eee9812f-ed23-46c2-967a-cc879579ca96',
      },
      user_accessible: false,
      toggle: true,
      toggleMe: true,
      Section2: true,
      backJumpObj: {
        link: '#',
        location: '',
      },
      members: [],
      auxs: [],
    }
  },
  methods: {
    ...mapActions({
      getControlPermissions: 'user/getControlPermissions',
      getElectionCommitteeMembers: 'election/getElectionCommitteeMembers',
      getElectionCommittee: 'election/getElectionCommittee',
      setMemberType: 'election/setMemberType',
      setLoadingStatus: 'menu/setLoadingStatus',
    }),
    async checkPermissions() {
      await this.getControlPermissions(this.secured_controls.edit_camp_elections).then(() => {
        this.user_accessible = this.determineAccessibility(this.secured_controls.edit_camp_elections)
      })
    },
    handleEditClick() {
      this.$router.push({ name: 'edit-camp-election' })
    },
    async pageLoad() {
      try {
        this.setLoadingStatus(true)
        await Promise.all([
          await this.getViewTranslations(),
          await this.getComponentTranslations('security-banner'),
          await this.getComponentTranslations('common.officer-titles'),
          await this.getElectionCommitteeMembers(),
          await this.getElectionCommittee(),
          await this.checkPermissions(),
        ]).then((results) => {
          this.stripReadableText(results[2])
          const componentTranslations = results[1]
          this.$set(this.translations, 'components', componentTranslations)
          this.updateTranslatedOfficeTitles(['gideonMemberDetails', 'auxiliaryMemberDetails'])
        })
      } catch (e) {
        console.error(e)
      } finally {
        this.setLoadingStatus(false)
      }
    },
  },
  async created() {
    await this.pageLoad()
  },
  computed: {
    ...mapGetters({
      auxiliaryMemberDetails: 'election/auxiliaryMemberDetailSelect',
      campNumber: 'user/userCampNumber',
      committeeDetails: 'election/committeeDetailSelect',
      gideonMemberDetails: 'election/gideonMemberDetailSelect',
      isInMenu: 'menu/isInMenu',
      officerToolbarActive: 'user/officerToolbarActive',
      officerToolbarSelected: 'user/officerToolbarSelected',
      prefCulture: 'user/userPreferredCulture',
      userLocation: 'user/userLocation',
      userLogin: 'user/userLogin',
      userName: 'user/userName',
    }),
    allowPage() {
      let hq = constantData.super_roles.find((csr) => csr.name === 'Headquarters')
      if (!!hq && !!this.userLogin.croles) {
        return this.isInMenu(this.$route.path) || this.userLogin.croles.filter((cf) => cf.rle_key === hq)
      } else {
        return false
      }
    },
    currentCamp() {
      if (this.officerToolbarActive) {
        return { campname: this.officerToolbarSelected.camp_name, campnumber: this.officerToolbarSelected.camp_number }
      } else {
        return { campname: this.userLogin.campname, campnumber: this.userLogin.campnumber }
      }
    },
  },
  components: {
    BackJump: BackJump,
    iCalendar: iCalendar,
    pageBody: pageBody,
    pageFooter: pageFooter,
    pageHeader: pageHeader,
    securityBanner: SecurityBanner,
    ToggleArrow: ToggleArrow,
  },
}
</script>

<style lang="scss">
@import '@/styles/settings.scss';
@import '@/styles/bootstrap/b-button.scss';
@import '@/styles/bootstrap/b-button-group.scss';

h3 {
  line-height: 1;
}

.page-header {
  @include breakpoint(sm) {
    flex-wrap: wrap;
    h1 {
      width: 100%;
    }
    .btn {
      width: 100%;
    }
  }
}

.page-body {
  padding: 40px 15px 80px 15px;
}

.bg-block {
  padding: 45px 40px;
  @include breakpoint(sm) {
    padding: 25px 20px;
  }
}

.section-1,
.section-2,
.section-3 {
  .d-flex {
    @include breakpoint(sm) {
      flex-wrap: wrap;
    }
    .col {
      @include breakpoint(sm) {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}

.section-1 {
  .d-flex {
    margin-left: -15px;
    margin-right: -15px;
    @include breakpoint(sm) {
      margin-left: 0;
      margin-right: 0;
    }
  }
  .col {
    &:nth-of-type(1) {
      width: 20%;
    }
    &:nth-of-type(2) {
      width: 20%;
    }
    &:nth-of-type(3) {
      width: 25%;
    }
    &:nth-of-type(4) {
      width: 35%;
    }
  }
}

// .section-1-notes {
//   min-height: 87px;
//   @include breakpoint(sm) {
//     min-height: 66px;
//   }
// }

hr {
  // margin: 68px 0 34px;
  @include breakpoint(sm) {
    margin: 40px 0;
  }
}

form {
  select.form-control.flex-1 {
    flex: 1 1 auto;
  }
}

.members {
  flex-wrap: wrap;
  .member {
    max-width: 33.3333%;
    margin-bottom: 40px;
    padding-right: 15px;
    @include breakpoint(sm) {
      max-width: 100%;
      width: 100%;
    }
    h3 {
      margin-bottom: 28px;
      font-family: $open-sans;
      font-size: 18px;
      letter-spacing: 0.6px;
      line-height: 24px;
    }
    div {
      margin-bottom: 15px;
      font-size: 16px;
      font-weight: 700;
      letter-spacing: 0px;
      line-height: 22px;
      &:last-of-type {
        margin-bottom: 0;
      }
      svg {
        margin-right: 16px;
      }
    }
  }
}

.start-date,
.term-number {
  text-transform: uppercase;
}

// MOVE GLOBAL
.toggle-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
